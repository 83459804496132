$primary-color: #d61a0d;
$primary-dark-color: #4b0e03;
$primary-lighter-color: #d23013;
$secondary-color: #10106a;
$secondary-dark-color: #09093e;
$secondary-lighter-color: #8889b5;
$ternary-color: #7b38f8;

$primary-bg: #244632;
$secondary-bg: #43825d;

$success: #3f9c35;
$error: #cc0000;
$warning: #ec7a08;
$info: #00b9e4;
$active: #244632;

$gray: #aaaaaa;
$cloud: #f1f1f1;
$coal: #444444;

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

body {
  font-family: 'Poppins', sans-serif;
  // background: #2e74a4;
}

p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: #999;
  text-decoration: none;
  transition: all 0.3s;
}

.topHeader {
  background: #1c2229;
}

.navRight {
  margin-top: 4px;
  float: right;
}

.topNav {
  padding: 0;
  margin: 5px 0;
  width: 100%;
}

.topnav-dropdown {
  top: 32px !important;
  border-radius: 0px;
}

.nav-scroll {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  -ms-overflow-style: none;
}

.nav-scroll::-webkit-scrollbar {
  display: none;
}

.nav-custom {
  background: $primary-bg;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  color: white;
  padding: 15px 15px;
  margin: 0 3px;
  font-weight: 600;
  font-size: 1.2rem;
}

.c-i {
  font-size: 40px;
}

.btn-number {
  border-radius: 0 !important;
}

.prod-icon > i {
  font-size: 30px;
  margin-bottom: 15px;
}

.search-input {
  border: 0;
  height: 55px;
  border-radius: 0px;
}

.input-group-text {
  border: 0px;
  border-radius: 0px;
  background: white;
}

.input-number {
  flex-basis: 35px !important;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  padding: 6px;
  text-align: center;
}

.plus-icon {
  font-size: 20px;
  background: $secondary-bg;
  color: white;
  padding: 9px 4px;
}

.minus-icon {
  font-size: 20px;
  color: white;
  padding: 9px 4px;
}

.products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 20px;
  margin-left: -15px;
}

.padd0 {
  padding: 0;
}

.product-container {
  height: 100%;
  overflow-y: scroll;
}

.tier-wrapper {
  padding: 0 4px 0 20px;
}

.prod-list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: #feffff;
  border: 1px solid $secondary-bg;
  min-height: 85px;
  margin-bottom: 8px;
  padding: 5px 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.t-selected {
  background: $primary-bg;
  color: $primary-font-color;

  h6 {
    font-weight: 700;
    font-size: 1.3rem !important;
    line-height: 22px !important;
  }
}

.prod-title {
  justify-content: normal;
  line-height: 10px;
  padding: 5px;

  h6 {
    line-height: 15px;
  }
}

.prod-title > h6 {
  font-size: 20px;
  font-weight: bold;
  color: $primary-bg;
}

.prod-price {
  display: flex;
  span {
    font-size: 25px;
    font-weight: bold;
  }
}

.cart {
  padding: 0 0 0 5px;
  // height: 100%;
  width: 30%;
  // border-right: 1px solid $secondary-bg;

  .cart-actions {
    width: 10%;
    padding: 0;

    button {
      border-radius: 0;

      i {
        width: 13px;
      }
    }

    a {
      border-radius: 0;
      padding: 8px !important;
      font-size: 0.8em !important;
      display: block !important;
      color: white !important;

      i {
        width: 13px;
      }
    }
  }

  .cart-content {
    width: 75%;
    padding: 0 1px;
    margin: 0;

    .item-title {
      width: 80%;
    }

    .item-price {
      width: 20%;
      font-size: 0.9rem;
    }
  }

  .cart-active {
    background: #e9bdff;
  }

  .cart-quantity {
    width: 15%;
    padding: 0;

    i {
      width: 100%;
      text-align: center;
      height: 35px;
      padding-top: 7px;
      font-size: 24px;
    }

    input {
      height: 30px;
      font-size: 30px;
      padding-top: 3px;
      font-weight: bold;
      border-radius: 0;
    }
  }
}

.cart-header {
  color: $secondary-bg;
  font-weight: 900;
  font-size: 1.2rem;
  border: none;
  background: transparent;
  padding: 10px;
  text-transform: uppercase;
}

.cart-header:hover {
  color: #2e74a4;
}

.cart-footer {
  color: $secondary-bg;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9em;
  line-height: 1.7em;
  font-weight: 500;
  border: none;
  background: transparent;
  padding: 10px;
}

.cart-footer:hover {
  color: #2e74a4;
}

.toggle-wrapper {
  top: 16px !important;
  padding: 0 !important;

  .checkout-toggle {
    padding: 10px;
    border-top: 1px solid $cloud;
    color: black;
    font-weight: 900;
    font-size: 1.2rem;
  }
}

.input-checkout {
  width: 100%;
  border: 0px;
  color: white;
  height: 50px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 0;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout-btn {
  height: 63px;
  width: 100%;
  border: 0px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  border-radius: 0;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  height: 90vh;
}

#sidebar {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  max-width: 100%;
  background: #fff;
  color: #fff;
  transition: all 0.3s;
  height: 100%;

  .cart-notes {
    p {
      font-size: 0.5rem;
      color: black;
      width: 100%;
      padding: 0 2px;
      height: 25px;
    }
  }
}

#sidebar.active {
  margin-left: -400px;
}


.postcode-finder {
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    height: 50px;
  }
}

#sidebar .sidebar-header {
  position: relative;
  z-index: 2;
  padding: 5px 0px;
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid $secondary-bg;
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #47748b;
}

#sidebar ul p {
  color: #000;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
  color: black;
}

#sidebar ul li a:hover {
  color: #7386d5;
  background: #fff;
}

#sidebar ul li.active > a,
a[aria-expanded='true'] {
  color: #fff;
  background: #fff;
}

a[data-toggle='collapse'] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

.list-group-item {
  color: #212529;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: $secondary-bg;
  border-color: $secondary-bg;
}

.tree-view {
  list-style: none;
  margin-left: -25px;
}

.tree-view > li {
  font-size: 11px;
}

.amount {
  float: right;
}

.cart-items {
  height: 54%;
  overflow-y: scroll;

  .list-group-item {
    padding: 0 2px;
  }
}


.sub-total {
  padding: 5px 15px;
  background: $secondary-bg;
  font-size: 1.3rem;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 700;
}

.discount {
  padding: 5px 15px;
  background: $secondary-bg;
  font-size: 1.3rem;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 700;
}

.remove-discount {
  color: #cc0000;
  margin-right: 6px;
  margin-left: -10px;
}

.payable {
  padding: 5px 15px;
  background: $secondary-bg;
  font-size: 1.3rem;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 700;
}

.subAmount {
  float: right;
  color: white;
}

.receipt-divider {
  border-bottom: 2px dashed black;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.form-controll {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#content {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 70%;
  padding: 0 5px;
  height: 100%;
  margin: 0 auto;
}

.btn-primary {
  background: $primary-color;
  border-color: $primary-color;

  &:hover {
    background: white;
    color: $primary-color;
    border-color: $primary-color;
  }
}

.parent-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $secondary-bg;
  padding: 0 20px;
}

.menu-item > a {
  color: #fff;
  font-weight: bold;
  padding: 20px;
  display: block;
}

.menu-item > button {
  color: #fff;
  font-weight: bold;
  padding: 20px;
  border: none;
  background: transparent;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    margin-left: -400px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}

.active-menu {
  // background: #27a744;
  background-color: white;
  color: $secondary-bg;
  border: 2px solid $secondary-bg;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.printHeadTitle {
  text-align: center !important;
}

.customer {
  .text-input {
    width: 100%;
  }
}

.menu-item {
  button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    padding: 8px;
    width: 115px;
  }

  button:focus {
    outline: 0;
  }
}

.bg-ternary {
  background: $ternary-color !important;
}

.incoming-calls {
  width: 440px;
  z-index: 999;
  top: 0;
  right: 0;
  position: absolute;
  background: transparent;

  .incoming-call {
    border: 1px solid gray;
    margin: 10px 0;
    background: white;

    .header {
      background: $primary-color;
      padding: 10px;

      p {
        font-size: 1.6rem;
        color: white;
        font-weight: bold;
        margin: 0;
      }
    }

    .body {
      padding: 10px;
    }

    .footer {
      display: flex;
      justify-content: space-around;
      padding: 5px 50px 20px;

      .ignore {
        background: $error;
        color: white;
        border-radius: 30px;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .accept {
        background: $success;
        color: white;
        border-radius: 30px;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .checkout-mode {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;

        .mods {
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          margin-bottom: 10px;

          p {
            color: $primary-font-color;
            margin: 0;
            font-size: 1.4rem;
            font-weight: 900;
          }

          .mod {
            flex: 1 1;
            justify-content: center;
            display: flex;
            align-items: center;
            background: $primary-bg;
            padding: 10px;
          }

          .takeaway {
            margin-right: 5px;
          }

          .delivery {
            flex: 1;
          }

          .selected {
            background: $success;
          }
        }

        button {
          border: none;
          padding: 20px;
          font-weight: 900;
          font-size: 1.5rem;
        }
      }
    }
  }
}

.section-header {
  width: 100%;
  background: $primary-bg;
  color: $primary-font-color;
  margin: 0 5px;
  padding: 5px 10px;
  font-weight: 700;
  font-size: 1.3rem;
}

.go-back {
  color: $primary-font-color !important;
  background: $secondary-color !important;
  position: absolute;
  width: 200px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px !important;
  font-size: 2rem !important;
  font-weight: 500 !important;
  top: 10px;
  right: 20px;
  z-index: 999;
}

input {
  border-radius: 0 !important;
}

button {
  border-radius: 0 !important;
  outline: none;
}

.update-system {
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;
  button {
    color: $primary-font-color;
    background: $secondary-color;
    width: 300px;
    height: 80px;
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 40vh;
}

.grand-total {
  td {
    font-weight: bold !important;
  }
}

.from-datetime, .to-datetime {
  border: 1px solid $primary-bg;
  margin: 0 5px;
  padding: 5px;
  h3 {
    width: 100%;
  }
  .datetime {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .form-group {
      flex: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 5px;

      label {
        margin: 0 3px 0 0;
        width: 18%;
      }
      input {
        height: 40px;
        width: 55%;
      }

      .arrow {
        width: 100%;
        display: flex;
        justify-content: space-around;
        i {
          font-size: 2rem;
          border: 1px solid $coal;
          padding: 5px;
        }
      }
    }

    .time {
      flex: 1;
      input {
        width: 60%;
      }
    }
  }

}


